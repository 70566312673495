:root {
    --primary: white;
}

.btn {
    padding: 8px 20px;
    border-radius: 3px;
    background-color: aliceblue;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn:hover {
    /* padding: 8px 20px; */
    transition: all 0.3s ease-out;
    background-color: #30bced;
    transition: 300ms;
}

.btn--primary {
    /* background-color: var(--primary-color); */
    background-color:aliceblue;
}

.btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border-radius: 3px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    border-radius: 3px;
    font-size: 16px;
    color: black
}

.btn--large {
    padding: 12px 26px;
    border-radius: 3px;
    font-size: 20px;
    color: white
}