.tenant-list-container {
  margin-top: 100px;
  height: 100%;
  width: 100%;
}

.tenant-card {
  width: 320px;
  border-radius: 4px;
  box-shadow: 3px 3px 15px #dcdcdc;
  background-color: #fff;
  padding: 12px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  height: 300px;
}

#tenant-card {
  height: unset;
}

.tenant-card-app-container {
  height: 150px;
  overflow: auto;
   
}
.tenant-name-container {
  margin: auto;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
}
.tenant-name {
    font-weight: 500;
    font-size: 20px;
    /* flex-grow: 1; */
    color: #3c3c3c;
    font-family: 'Roboto';
}

.fa-gear
{
    
    font-size: 21px;
 
    color: #9c9c9c;
    transition-duration: 300ms;
    
}

.fa-area-chart
{
  font-size: 21px;
  color: #9c9c9c;
  float: right;
  padding-right: 5px;
}
.fa-a
{
    
    font-size: 21px;
    color: #9c9c9c;
    transition-duration: 300ms;
    
}
.fa-info-circle
{
    margin-top: -7px;
    font-size: 14px;
    color: #9c9c9c;
}

.fa {
  font-size: 20px;
}

.fa-user {
  color: #30bced;
}