.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid blue;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.button-loader {
  align-items: center !important;
  border-radius: 50% !important;
  justify-content: center !important;
  display: flex !important;
  -webkit-animation: spin 2s linear infinite !important;
  animation: spin 2s linear infinite !important;
  margin: auto;
}

.button-load {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #ee3e56;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-listing {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  padding-top: 150px;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .loader-listing {
    padding: 20px 0;
  }
}
