.navbar-items {
  margin-left: -8px;
  position: fixed;
  top: 0;
  background: #333333;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 999;

  box-shadow: 3px 3px 15px #4d4d4d;
}

.navbar-logo {
  color: #f84137;
  justify-self: center;
  font-family: "Roboto";
  margin-left: 20px;
  cursor: pointer;
}

.copy-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.copy-icon:active {
  opacity: 0.5;
}

.display-none {
  display: none;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: end;
}
.navbar-items ul {
  margin-top: 15px !important;
}

.nav-links {
  color: white;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 100px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.nav-links:hover {
  color: #f37129;
}
.Active {
  color: #3599cb !important;
  text-decoration: underline;
}

.fa-bars {
  color: white;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 700px) {
  .navbar-items {
    /* position: relative; */
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    /* height: 100%; */
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .active {
    background: #333333;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-inline-start: 0;
  }

  .nav-links {
    text-align: center;
    font-size: 20px;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #f15951;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 40%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    color: white;
    top: 0;
    right: 0;
    font-family: "Roboto";
    font-weight: 100;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
  }

  .fa-bars {
    color: #fff;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    font-family: "Roboto";
    font-weight: 500;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 3px;
    width: 80%;
    background: #f5665b;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #f84137;
    transition: 250ms;
  }

  button.mobile {
    display: none;
  }
}


.environment-title
{
  color:#a6a6a6;
  margin-left:55px;
  width: 300px;
}
.env-item {
  padding :10px
}