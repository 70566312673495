.all-selector {
  background-color: #fff;
  border: none !important;
  color: #757575;
  width: fit-content;
  width: -moz-fit-content;
  -webkit-appearance: none;
  appearance: none;
  padding: 14px 18px 14px 18px;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  width: 50%;
  text-align: center;
}

.user-dropdown {
  width: 150px;
  height: 50px;
  padding: 2px;
  border-radius: 5px;
  margin: auto;
}
.Button-Conatiner-AddAppilication {
  display: flex;
  margin-bottom: 35px;
}
.Button-Conatiner-AddAppilication .config-submit-button {
  margin-left: auto !important;
}
.config-submit-button {
  display: flex;
  float: right;
  color: #505050;
  font-size: 16px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 0px 10px 6px 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  background-color: #30bced;
  margin-top: 40px;
  width: 100px;
}

.input-container-add {
  width: 80%;
}

.add-button {
  height: 48px;
  width: 40%;

  margin-bottom: 15px;
  align-items: center;
  margin-top: 30px;
  background-color: #30bced;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  font-size: 4px;
  border-radius: 4px;
  margin-bottom: -30px;
}
.success-message {
  width: 400px;

  padding: 16px;
  margin-left: 30px;
  border-radius: 5px;
  font-family: Arial;
  font-size: 11px;
  text-transform: uppercase;
  background-color: rgb(236, 255, 216);
  color: green;
  text-align: center;
  margin-top: 30px;
}
.error-message {
  width: 400px;

  padding: 16px;
  margin-left: 30px;
  border-radius: 5px;
  font-family: Arial;
  font-size: 11px;
  text-transform: uppercase;
  background-color: rgb(236, 255, 216);
  color: #ff6c0a !important;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}
.section-title {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #333;
  font-family: "Roboto";
  font-size: 25px;
  font-weight: 300;
}

.section-divider {
  width: 100%;
  padding: 0 20%;
}
.all-selector option {
  font-size: 14px;
}
.option-css {
  border: solid 1px #ccc !important;
  padding: 10px;
}

.act-btns {
  text-align: right;
}
.act-btns div {
  display: inline-block;
  cursor: pointer;
}

.alert-popup {
  width: 500px !important;
  z-index: 2400 !important;
  position: fixed !important;
}
.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.tooltip-container .fa-info-circle {
  float: none !important;
}
.tooltip-container .tooltiptext {
  width: 200px !important;
  margin-left: 10px !important;
  padding: 0px !important;
  font-size: 12px !important;
}
