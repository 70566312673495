
.heading {
	margin: 25px 0px 10px 0px;
	color: #333;
	font-family: 'Roboto';
	font-size: 35px;
	font-weight: 300;
}

.section-title {
	display: inline-block;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 10px;
	color: #333;
	font-family: 'Roboto';
	font-size: 25px;
	font-weight: 300;
}

.section-divider {
	width: 100%;
	padding: 0 20%;
}

.section-divider hr {
	padding: 0;
	margin : 20px 0;
	height: 3px;
	width: 100%;
}

.form-container {
	width: 700px;
    margin: 100px auto;
    padding: 50px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 5px 5px 20px #dadada;
	display: block;
	height: fit-content;
}
 

@media screen and (max-width: 600px) {
	.form-container {
		width: 100%;
		padding: 20px;
		border: none;
		background-color: transparent;
		box-shadow: none;
	}
}

.input-container { 
	float: left;
	width: 49%;
}
.image{
	margin-top: 15px
}

.right {
	float:right;
}

.full-width {
	width: 100%;
}

.col70 {
	float: left;
	width: 70%;
}

@media screen and (max-width:600px) {
	.input-container {
		width: 100%;
	}

	.right {
		float: left;
	}

	.col70 { 
		width: 70%;
	}
}

label {
	margin: 10px 0;
	font-family : "Roboto";
	font-size : 12px;
	font-weight : 500;
	text-transform: uppercase;
	color: #666;
}

input, label {
	display: block;
}

input, select{
	width: 100%;
	height: 45px;
	margin-bottom: 15px;
	border: 1px solid #bebebe;
	outline: none;
	background-color: white;
	padding-left: 10px;
	font-size: 16px;
	border-radius: 3px;

	/* transition-duration: 200ms; */
}

input:hover {
	border: 1px solid #888888;
}

.col50 {
	float: left;
	width: 50%;
}

.col45 {
	float: left;
	width: 45%;
}

::placeholder {
	color: #bebebe;
}

input:focus {
	border: 2px solid black;
}

button.submit {
	width: 100%;
	height: 50px;
	margin: 0;
	border: none;
	outline: none;
	color: white;
	background-color: #f5665b;
	border-radius: 3px;
	font-size: 15px;
	font-family: 'Roboto';
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	transition-duration: 300ms;
}

button.submit:hover {
	background-color: #F84337;

	transition-duration: 300ms;
}

select:focus {
	border: 2px solid black;
}

select:invalid {
	color: #bebebe;
}

/* environment styles */

hr {
	height: 2px;
	margin-bottom: 20px;
	background-color: #bebebe;
}

.environment-container {
	transition: all 0.5s;
}
.tenant-add-form .environment-container
{
	display: flex;
	flex-direction: column;
}
.tenant-add-form .header-env
{
	margin:10px 0px 10px 0px !important;
}

.tenant-add-form .del-env{
	background-color: #f5665b;
	float: left;
    color: white;
    font-weight: bold;
    height: 45px;
    width: 25%;
    border: none;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    transition-duration: 300ms;
    margin-left: 30px;
}

.line-env {
	width: 100%;
	padding: 0 20%;
	margin: 25px auto 25px auto;
}

.line-env hr {
	height: 2px;
	width: 100%;
	background-color: #bebebe;
}

.del-env { 
	float: right;
	background-color: #f5665b;
	color: white;
	font-weight: bold;
	height: 45px;
	width: 25%;
	border: none;
	outline: none;
	border-radius: 3px;
	cursor: pointer;

	transition-duration: 300ms;
}

.del-env:hover {
	background-color: #F84337;
}

.header-env {
	margin: 30px auto 10px auto;
	color: #333;
	font-family: 'Roboto';
	font-size: 15px;
	font-weight: 300;
}

.add-env {
	margin: 10px auto ;
	border-radius: 4px;
	background-color: lightblue;
	border: none;
	outline: none;
	color: #444;
	text-align: center;
	padding: 10px;
	width: 200px;
	height: 45px;
	transition: all 0.5s;
	cursor: pointer;
}

.add-env span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 15px;
	font-weight: 600;
	transition: 0.5s;
}

.add-env span:after {
	content: '+';
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}

.add-env:hover span {
  	padding-right: 15px;	
}

.add-env:hover span:after {
	opacity: 1;
	right: 0;
}
.input-custom{
	margin-left:5px
}
.error-message {
	width: 400px;
	
	padding: 16px;
	margin-left: 30px;
	border-radius: 5px;
	font-family: Arial;
	font-size: 15px;
	text-transform: uppercase;
	background-color: rgb(59, 59, 46);
	color: #ff6c0a !important;
	text-align: center;
	font-weight: bold;
	margin-top: 30px;
  }
  .closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.tenant-add-form .add-button{
 width: 200px;
}