.display-message{
    width: 500px;
    padding: 15px;
    border-radius: 5px;
    font-family: revert;
    font-size: 14px;
    text-transform: uppercase;
    background-color: rgb(12 12 12);
    color: #50cee4 !important;
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
}
