.user-status
{
    display: inline-block;
}
.user-status input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    display: inline-block;
}
.user-active-title {
     
    float: right;
    padding: 10px;
}
.addUser-button .config-submit-button {
    margin-top: 0px !important;
    margin-bottom: 30px;
}
