.arrowKey{
    height: 1rem;
    width: 1rem;
    margin: auto;
}
.acoordian-wrapper{
  

    display: flex;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: aliceblue;
   
}
.acoordian-wrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.accordian-container{
    margin: 1rem;
    transition: transform .35s ease-in-out;
}

.title-class{
    font-weight: 400;
    font-size: 22px;
}
.arrowkey-wrapper{
    height: 2rem;
    width: 2rem;
    border: 1px solid gray;
    border-radius: 50%;
    background-color: white;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background-color: #868e96;
}
.app-wrapper{
    height: 4rem;
    padding:1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  
   
  
}
.tenant-info{
    display: flex;
    height: 100%;
}

.app-container{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.font-weight{
    font-weight: 400;
}
.tenant-id{
    width: 100px;
    padding: 1rem;
    text-align: center;
    background-color: #868e96;
    font-weight:bold;
    color: white;
}
.tenant-name-class{
    text-align: center;
    padding: 1rem; 
}
.tenant-key{
    width: 200px;
    text-align: center;
    padding: 1rem;
   
    background-color: #f1f1f1;
}
.applications-info th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #868e96;
    color: white;
  }

  .applications-info {
    border-collapse: collapse;
    width: 100%;
  }
  
  .applications-info td, .applications-info th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .applications-info tr:nth-child(even){background-color: #f2f2f2;}
  
  .applications-info tr:hover {background-color: #ddd;}

.head{
    height: 35px !important;
}
