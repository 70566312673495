.view-tenant-container{
    margin: 100px ;
    width: 100%;
}
.head1{
    padding: 0% !important;
}
.head2{
    padding: 0% !important
}
.head3{
    padding: 0% !important;
    margin-left: 200px;
    }