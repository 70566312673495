body {
	padding: 0;
	margin: 0;
	height: 100vh;
}

#app {
	display: flex;
	height: inherit;
}

#root {
	height: inherit;
}

p {
	margin: 0;
}


.login-heading {
	margin: 20px 0px 15px 0px;
	color: #333;
	font-family: 'Roboto';
	font-size: 35px;
	font-weight: 300;
}

.login-form-container {
	width: 400px;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 5px 5px 20px #dadada;
    display: block;
	margin: auto;
	height: unset;
}

.errorMessage
{
	color: red;
	text-align: center;
}
.login-form-container form input {
	height: 25px;
	width: 380px;
	padding: 10px;
}

@media screen and (max-width: 500px) {    
    .login-form-container {
        width: 100%;
		padding: 25px;
		border: none;
		background-color: transparent;
		box-shadow: none;
    }
}
.choice{
	width: 100%;
}

.login-input-container {
    width: 100%;
    margin: 15px 0;
}

label {
	margin: 10px 0;
	font-family : "Roboto";
	font-size : 12px;
	font-weight : 500;
	text-transform: uppercase;
	color: #666;
}

input, label {
	display: block;
}

input{
	width: 100%;
	height: 45px;
	margin-bottom: 15px;
	border: 1px solid #bebebe;
	outline: none;
	background-color: white;
	padding-left: 10px;
	font-size: 16px;
	border-radius: 3px;

	/* transition-duration: 200ms; */
}

input:hover, select:hover {
	border: 1px solid #888888;
}

::placeholder {
	color: #bebebe;
}

input:focus, select:focus {
	border: 2px solid black;
}

.submit {
	width: 100%;
	height: 50px;
	margin: 30px 0 40px 0;
	border: none;
	outline: none;
	color: white;
	background-color: #f5665b;
	border-radius: 3px;
	font-size: 15px;
	font-family: 'Roboto';
	display: flex;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	cursor: pointer;

	transition-duration: 300ms;
}

.submit:hover {
	background-color: #F84337;

	transition-duration: 300ms;
}
