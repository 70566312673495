.custom-button {
  height: 48px;
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
  align-items: center;
  margin-top: 15px;
  background-color: #30bced;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  font-size: 14px;
  border-radius: 4px;

  margin-bottom: -2px;
}
.injected-hook-button {
  height: 48px;
  width: 45%;
  margin: auto;
  margin-bottom: 15px;
  align-items: center;
  margin-top: 15px;
  background-color: white;
  border:2px solid #aaa3a3;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: -2px;
}

.injected-hook-button .button-text {
  color:black !important;
  font-weight: 400;
}
.disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  pointer-events: none;
}
.button-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: auto;
  font-size: 16px;
  font-family: "Roboto";
}
