.a-input{
    border: 1px solid #dedede;
    clear: both;
    text-indent: 4px;
    width: 100%;
    border-radius: 4px;
    padding: 12px 4px;
}

.input-errormessage{
    font-family: "Nunito";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ee503b;
  }
.input.error{
    border: 1px solid #ee503b !important;
}

