.config-heading {
  margin: 25px 0px 15px 0px;
  color: #333;
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 300;
}

.config-container {
  display: flex;
  margin: 100px auto;
  flex-direction: row;
  width: 95%;
  padding: 50px 0 70px 0px;

  background-color: #f9f9f9;
}
.textDecoration-none {
  text-decoration: none;
}
.config-navbar {
  /* position: fixed; */
  align-items: flex-start;
  width: 30%;
  padding: 0 30px 0 50px;
}

.config-body {
  width: 70%;
  max-height: 100vh;
  padding: 0 30px 20px 10px;
  overflow-y: auto;
  margin-bottom: -100px;
  display: flex;
  flex-direction: column;
}
.attribute-priority-input {
  width: 50%;
}
.application-name-input {
  width: 100%;
}
.application-key-input {
  width: 60%;
  margin-left: 28px;
}

@media screen and (max-width: 1020px) {
  .config-container {
    width: 800px;
  }
}

@media screen and (max-width: 700px) {
  .config-container {
    width: 90%;
    max-height: 60vh;
    margin: 0 auto;
    padding: 20px 0;
    border: none;
    background-color: #f9f9f9;
  }

  .config-navbar {
    padding: 100px 10px 0 20px;
  }

  .config-body {
    padding: 0 20px 0 10px;
  }
}

/* navbar */

.menu-items {
  padding: 100px 0 0 0;
  list-style: none;
}

.menu-item {
  padding: 0 0 25px 10px;
  border-left: 2px solid #dfdfdf;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
  color: #696969;
  cursor: pointer;
}
.custom-publish-popup-prod {
  display: flex;
  flex-direction: column;
}
.custom-publish-popup-staging {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 10px;
}
.publish-title {
  padding-top: 5px;
  padding-left: 10px;
  width: 100%;
  padding-bottom: 5px;
  font-weight: 400;
}
/* 
.publish-title-disabled {
  font-weight: 300;
  cursor: default;
  padding-top: 5px;
  padding-left: 10px;
  width: 100%;
  padding-bottom: 5px;
} */

.publish_icon_disabled {
  font-weight: 300;
  cursor: default;
  display: flex;
  color:   #cccccc;
}

.publish_menu_env {
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  height: 30px;
  padding-left: 14px;
  text-transform: uppercase;
}
.custom-js .custom-modal-body {
  padding: 10px !important;
}
.custom-js .custom-modal-content {
  width: 250px;
}
.publish_icon_staging {
  display: flex;
}

.menu-item:hover {
  color: #333;
  /* border-left: 2px solid #F84337; */
  border-left: 2px solid #2899c1;
}

.menu-item.active {
  color: black;
  border-left: 2px solid #f84337;
}

.back-button {
  text-decoration: none;
  padding: 10px;
  border-radius: 100%;
  color: white;
  background-color: #696969;
  transition-duration: 300ms;
  width: 40px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
}

/* body */

.full-width {
  width: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media screen and (max-width: 700px) {
  .flex {
    flex-direction: column;
  }
}

label {
  margin: 10px 0;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #666;
}

input[type="radio"] {
  margin: 0;
  padding: 0;
  width: 20px;
  float: left;
}

.textarea-label {
  margin: 5px 0 10px 0;
  color: #333;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1px;
}

.textarea-label .blue {
  color: #2899c1;
}

.config-textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #bebebe;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
}

.config-input,
label {
  display: block;
}

.config-input {
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  border: 1px solid #bebebe;
  outline: none;
  background-color: white;
  padding-left: 10px;
  font-size: 16px;
  border-radius: 3px;

  /* transition-duration: 200ms; */
}

.config-input:hover,
.config-textarea:hover {
  border: 1px solid #888888;
}

::placeholder {
  color: #bebebe;
}

.config-input:focus,
.config-textarea:focus {
  border: 2px solid black;
}

.enable {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
}

.enable:hover {
  cursor: pointer;
}

.enable-text {
  display: inline-block;
  line-height: 20px;
  padding: 0 0 20px 10px;
  vertical-align: top;
  font-family: "Roboto";
  font-weight: 400;
}

.threshold {
  display: inline-block;
  width: 20%;
}

.threshold-text {
  display: inline-block;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  padding: 15px;
}

button.submit {
  width: 100%;
  height: 50px;
  margin: 0;
  border: none;
  outline: none;
  color: white;
  background-color: #f5665b;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;

  transition-duration: 300ms;
}

button.submit:hover {
  background-color: #f84337;

  transition-duration: 300ms;
}

/* Config url style */

.url-config-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.url-config-header .config-input {
  width: 85%;
  margin: 0;
}

.url-config-header .fa-plus {
  margin: auto;
  font-size: 20px;
  color: #929292;
  cursor: pointer;

  transition-duration: 300ms;
}

.url-config-header .fa-plus:hover {
  color: #333;
  font-size: 22px;
}

.url-config-list {
  margin-bottom: 20px;
  padding: 0;
  list-style-type: none;
}

.url-config-list li {
  display: flex;
  padding: 5px 15px;
  /* margin: 5px 0; */
  border-radius: 2px;
  border-left: 3px solid #bebebe;
  justify-content: space-between;
  list-style-type: none;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 100;
}

.url-config-list li:hover {
  border-left-color: #6b6b6b;
  font-weight: 300;
}

.url-config-list li p {
  width: 80%;
}

.url-config-list li .fa-trash {
  margin: auto;
  cursor: pointer;
  font-size: 25px;
  color: #bebebe;

  transition-duration: 300ms;
}

.url-config-list li .fa-trash:hover {
  color: #f84337;
}

/* environment setup */

.header-env {
  margin: 30px auto 10px auto;
  color: #333;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
}
.test:after {
  content: "\2807";
  font-size: 30px;
}
.environment {
  display: flex;
  flex-direction: column;
  padding: 14px 14px 10px 0;
}
.env-title {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.2;
  font-size: 14px;
  padding: 10px 0;
}
.env-name {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2;
  font-size: 14px;
  padding: 10px 0;
}
.test {
  padding: 20px;
}
.publish-container {
  display: flex;
  width: 600px;
}

.name-env {
  width: 50%;
  margin-right: 20px;
}

@media screen and (max-width: 700px) {
  .name-env {
    width: 100%;
    margin-right: 0;
  }
}

.default-env-text {
  display: inline-block;
  line-height: 20px;
  padding: 12px 0 12px 10px;
  vertical-align: middle;
  font-family: "Roboto";
  font-weight: 300;
}

hr {
  margin: 20px 20%;
}

.tooltipConatiner {
  position: relative;
  display: inline-block;
  margin-left: 12px;
}

.application-script-container {
  display: flex;
  margin-top: 30px;
}

.CodeMirror {
  background-color: #191717 !important;
  color: white;
}
.tooltipConatiner .tooltiptext {
  visibility: hidden;
  width: 330px;
  background-color: #484444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0.5;
}

.tooltipConatiner:hover .tooltiptext {
  visibility: visible;
}

.title-label {
  color: #00000096;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 20px;
}

.publish_icon {
  display: flex;
  cursor: pointer;
}

.publish_icon :hover {
  background-color: #f3f3f3;
}
