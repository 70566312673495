.custom-modal {
  position: fixed;
  z-index: 2000;
  display: flex;
  left: 0;
  top: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  border-radius: 5px;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(9, 0, 35, 0.5);
}

.custom-modal-header {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 20px;
  position: relative;
  font-weight: 700;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  font-weight: bold;
  font-family: "Roboto";
}

.custom-modal-header .backIcon {
  margin: 4px 8px 4px 0px;
  cursor: pointer;
}
.custom-modal-body {
  padding: 40px;
  background: #fff;
}
.custom-modal-footer {
  padding: 2px 16px 24px;
  font-size: 16px;
  color: #090023;
  text-align: center;
  border-radius: 0 0 5px 5px;
}
.modal-close {
  position: absolute;
  right: 20px;
  top: 24px;
  cursor: pointer;
}
.modal-back-arrows {
  position: absolute;
  left: 0px;
  top: 20px;
  cursor: pointer;
}
.modal-close-no-title {
  position: absolute;
  right: 20px;
  cursor: pointer;
  bottom: 8px;
}
